import React from "react";
import { Link } from "gatsby";

const Home = ({ content }) => (
  <section className="content-section align-center-flex landing-hero">
    <div className="container flexbox">
      <div className="col-8">
        <div className="hero-image">
          <img src="/img/hero-image.svg" alt="Hero Image" />
        </div>
        <h1>{content.header}</h1>
        <h2>{content.subheader}</h2>
        <hr className="brand-hr" />
        <p>{content.paragraph}</p>
        <Link to="/questions" className="cta">
          {content.button_text}
        </Link>
      </div>
    </div>
  </section>
);

export default Home;
