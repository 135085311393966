import React from "react";
import ReactGA from "react-ga";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Home from "../components/Home";
import Helmet from "react-helmet";

export default class IndexPage extends React.Component {
  render() {
    ReactGA.initialize("UA-131134248-1");
    ReactGA.pageview("/");

    const home_content = this.props.data.homeContent.frontmatter;
    const questions = this.props.data.questionsContent.frontmatter;

    return (
      <Layout
        progress={{
          current_index: 0,
          questions: questions.eco_questions.length
        }}
        content={questions.progress_bar}
      >
        <Helmet>
          <title>{home_content.meta_title}</title>
          <meta name="robots" content="noindex" />
          <meta name="description" content={home_content.meta_description} />
        </Helmet>
        <Home content={home_content} />
      </Layout>
    );
  }
}

export const indexPageQuery = graphql`
  query indexPage {
    questionsContent: markdownRemark(
      frontmatter: { templateKey: { eq: "questions-page" } }
    ) {
      frontmatter {
        eco_questions {
          header
        }
        progress_bar {
          questions_done
        }
      }
    }
    homeContent: markdownRemark(frontmatter: { path: { eq: "/" } }) {
      frontmatter {
        header
        subheader
        paragraph
        button_text
        meta_title
        meta_description
      }
    }
  }
`;
